body {
  background-color: #f7f7f7;
}

.board {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.square {
  width: 100px;
  height: 100px;
  font-size: 36px;
  font-weight: bold;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.square:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.text-warning {
  color: #ffc107;
}

.text-primary {
  color: #007bff;
}

.text-success {
  color: #28a745;
}

.btn {
  font-size: 18px;
}

.font_size_title {
  font-size: 60px !important;
}

.button_resetgame {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #3498db; /* Solid blue background */
  border: none;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.button_resetgame:hover {
  background-color: #02406a; /* Slightly darker blue on hover */
  transform: translateY(-2px); /* Slight lift effect */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Deepened shadow */
  color:  white;
}

.button_resetgame:active {
  background-color: #03456e; /* Even darker blue when active */
  transform: scale(0.98); /* Slight shrink on click */
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2); /* Softer shadow */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .square {
    width: 70px;
    height: 70px;
    font-size: 24px; /* Reduced font size for icons */
    margin: 3px; /* Smaller margin for mobile */
  }

  .fas, .far {
    font-size: 2.5rem; /* Reduce icon size */
  }

  .font_size_title {
    font-size: 40px !important; /* Reduce title font size */
  }

  .btn {
    font-size: 14px; /* Reduce button font size */
  }

  .button_resetgame {
    padding: 8px 15px; /* Smaller padding for the reset button */
    font-size: 16px; /* Reduce font size of the reset button */
  }
}
